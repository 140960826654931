import ApplicationController from 'admin/controllers/application_controller';

import submitFormViaFetch from 'utils/submit_form_via_fetch';

export default class extends ApplicationController {
  static targets = [
    'show',
    'edit',
    'form',
    'input',
    'showValue',
    'editButton',
  ];

  static values = {
    formUrl: {
      type: String,
    },
    formMethod: {
      type: String,
      default: 'POST',
    },
    formScope: {
      type: String,
    },
    mode: {
      type: String,
      default: 'show',
    },
    name: {
      type: String,
    },
    initial: {
      type: String,
      default: '',
    },
  };

  connect() {
    this.recordRowCheckbox = document.getElementById('batch_action_item_134');
  }

  modeValueChanged() {
    if (this.modeValue === 'show') {
      this.editTarget.classList.add('hidden');
      this.showTarget.classList.remove('hidden');
    } else {
      this.showTarget.classList.add('hidden');
      this.editTarget.classList.remove('hidden');
    }
  }

  initialValueChanged() {
    this.showValueTarget.textContent = this.initialValue;
  }

  toggleMode() {
    this.modeValue = this.modeValue === 'show' ? 'edit' : 'show';
  }

  cancel() {
    this.editTarget.value = this.initialValue;
    this.modeValue = 'show';
  }

  submit() {
    const value = this.inputTarget.value;

    fetch(this.formUrlValue, {
      method: this.formMethodValue,
      body: JSON.stringify({
        [this.formScopeValue]: {
          [this.nameValue]: value,
        },
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    });

    this.initialValue = value;

    if (value === '') {
      this.editButtonTarget.classList.remove('hidden');
    } else {
      this.editButtonTarget.classList.add('hidden');
    }

    this.toggleMode();
  }
}
