import jQuery from 'jquery';

import select2 from 'select2';
import * as bootstrap from 'bootstrap';
import * as Popper from '@popperjs/core';
import { router } from 'jqueryrouter';

const JQUERY = jQuery;

JQUERY.router = router;

const defineJQueryPlugin = (plugin) => {
  const name = plugin.NAME;
  const JQUERY_NO_CONFLICT = JQUERY.fn[name];

  JQUERY.fn[name] = plugin.jQueryInterface;
  JQUERY.fn[name].Constructor = plugin;
  JQUERY.fn[name].noConflict = () => {
    JQUERY.fn[name] = JQUERY_NO_CONFLICT;
    return plugin.jQueryInterface;
  }
};

defineJQueryPlugin(bootstrap.Alert);
defineJQueryPlugin(bootstrap.Button);
defineJQueryPlugin(bootstrap.Carousel);
defineJQueryPlugin(bootstrap.Collapse);
defineJQueryPlugin(bootstrap.Dropdown);
defineJQueryPlugin(bootstrap.Modal);
defineJQueryPlugin(bootstrap.Offcanvas);
defineJQueryPlugin(bootstrap.Popover);
defineJQueryPlugin(bootstrap.ScrollSpy);
defineJQueryPlugin(bootstrap.Tab);
defineJQueryPlugin(bootstrap.Toast);
defineJQueryPlugin(bootstrap.Tooltip);

// JQUERY.ajaxPrefilter(((options, originalOptions, xhr) => {
//   if (!options.crossDomain) {
//     return CSRFProtection(xhr);
//   }
// }));

window.jQuery = JQUERY;
window.$ = JQUERY;
window.bootstrap = bootstrap;

select2();

export default jQuery;
